import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["MessagesSearchBar"] */ "/vercel/path0/packages/ui/src/messages-search-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserSection"] */ "/vercel/path0/packages/ui/src/navbar/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSwitcher"] */ "/vercel/path0/packages/ui/src/theme-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/packages/ui/src/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/vercel/path0/packages/ui/src/ui/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/ui/link-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/packages/ui/src/ui/separator.tsx");
